import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import Layout from "@layout/index";
import TemplateList from "@/cms/template/template-list";
import {
  Center,
  Heading,
  Flex,
  Text,
  Tabs,
  TabList as ChakraTabList,
  TabPanels,
  Tab,
  TabPanel,
  useBreakpointValue,
  AccordionIcon,
  Box,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Accordion,
  Tag,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import BottomLayout from "@/layout/BottomLayout";
import SearchBar from "@/components/search-bar";
import Header from "@components/head";

const TabList = styled(ChakraTabList)`
  border: none;
  button {
    justify-content: flex-start;
  }
  button[aria-selected="true"] {
    color: #9f2aec;
    border-left: 4px solid #9f2aec;
    background-color: #fbf0ff;
  }
`;

const AccordionItemFix = styled(AccordionItem)`
  border: none;
  background: #ffffff;
`;

const bgColorList = ["#48D982", "#FF5DAA", "#FFDE4D", "#BD55FA", "#599EFF"];

export const Head = () => {
  return (
    <Header
      title="Free meeting agenda templates for teams, managers, and more - Airgram"
      description="Get meeting agenda templates for team meetings, 1-1 meetings, project meetings, and more! Prepare well for your next meeting!"
    />
  )
}

const TemplatePage: React.FC<any> = ({ data }) => {
  const tagNodes = data.allStoryblokTag.edges;
  const templateNodes = data.allStoryblokEntry.nodes;

  const [tabIndex, setTabIndex] = useState(0);
  const [searchField, setSearchField] = useState("");
  const tabListOrientation: "horizontal" | "vertical" = useBreakpointValue({
    base: "vertical",
    sm: "vertical",
    md: "vertical",
    lg: "horizontal",
    xl: "horizontal",
    "2xl": "horizontal",
  });

  const filteredTemplates = (details) =>
    details.filter((data) => {
      const content = JSON.parse(data.content);
      return content.title.toLowerCase().includes(searchField.toLowerCase());
    });

  const tagListMap = new Map<string, any>();
  let colorIndex = 0;
  tagListMap.set("All", templateNodes);

  tagNodes.forEach(({ node }) => {
    const filterTags = templateNodes.filter(({ tag_list }) =>
      tag_list.includes(node.name)
    );
    if (filterTags.length > 0) {
      filterTags.forEach((element) => {
        element.bgColor = bgColorList[colorIndex];
      })
      colorIndex = (colorIndex + 1) % 5;
      tagListMap.set(node.name, filterTags);
    }
  });

  let tagListMapKeys = Array.from(tagListMap.keys());
  // tagListMap按照这个顺序排序
  const compareArr = [
    "All",
    "One-on-One Meetings",
    "Team Meetings",
    "Agile Meetings",
    "Marketing Meetings",
    "Sales Meetings",
  ];
  tagListMapKeys.sort((a, b) => {
    let indexA = compareArr.indexOf(a);
    let indexB = compareArr.indexOf(b);
    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    } else if (indexA !== -1) {
      return -1;
    }
    return 1;
  })

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index > 0) {
      const keys = tagListMapKeys;
      const hash = keys[index].toLocaleLowerCase().replace(/\s+/g, "-");
      window.location.hash = hash;
    } else {
      history.replaceState(null, null, " ");
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    const keys = tagListMapKeys;
    const tabIndex = keys.findIndex(
      (key) => `#${key.toLocaleLowerCase().replace(/\s+/g, "-")}` === hash
    );
    if (~tabIndex) {
      setTabIndex(tabIndex);
    } else {
      setTabIndex(0);
    }
    setTimeout(() => {
      document.documentElement.scrollTop = 0;
    }, 0)
  }, [typeof window !== "undefined" && window.location.hash]);

  return (
    <Layout
      bottomLayout={
        <BottomLayout
          signGtmId="web_get_template_btn"
          description="Unleash the Power of Pre-built Meeting Templates."
          buttonText="Get Started"
          buttonLink="https://app.airgram.io/signup"
        />
      }
    >
      <Center mb="40px" w="full" flexDir="column">
        <Center py="40px" flexDir="column">
          <Heading as="h1" borderRadius="12px" textAlign="center">
            Meeting Agenda Templates
          </Heading>

          <Text textAlign="center" maxW="960px">
            Looking for a meeting agenda? You don’t have to start from scratch.
            The Airgram team has created 30+ templates that are ready-to-use
            (free!) .
          </Text>
        </Center>
        <Flex mt="40px" color="#220247" w="full">
          <Flex w="full" flexDir="column" minW="200px">
            {/* lg,xl,2xl情况下水平放置 */}
            {tabListOrientation === "horizontal" && (
              <Tabs
                index={tabIndex}
                orientation="vertical"
                onChange={handleTabsChange}
              >
                <TabList>
                  <Box pos="sticky" top="100px">
                    {tagListMapKeys.map((tab, index) => (
                      <Tab
                        key={index}
                        _hover={{
                          textDecor: "none",
                          bgColor: "#eaedf0",
                        }}
                        cursor="pointer"
                        borderRadius="0 8px 8px 0"
                        lineHeight="24px"
                        w={{
                          lg: "210px",
                          xl: "290px",
                          "2xl": "370px",
                        }}
                        textAlign="left"
                        p="16px"
                      >
                        {tab}
                      </Tab>
                    ))}
                  </Box>
                </TabList>
                <TabPanels pl="40px" pt="100px">
                  {tagListMapKeys.map((key, index) => {
                    return (
                      <TabPanel key={index} pos="relative">
                        <SearchBar
                          styleStuff={{
                            pos: "absolute",
                            w: "97.5%",
                            top: "-100px",
                          }}
                          callback={setSearchField}
                          searchField={searchField}
                        />
                        <TemplateList
                          templateList={filteredTemplates(tagListMap.get(key))}
                        />
                      </TabPanel>
                    );
                  })}
                </TabPanels>
              </Tabs>
            )}
            {/* base,sm,md情况下垂直放置 */}
            {tabListOrientation === "vertical" && (
              <Box>
                <SearchBar
                  styleStuff={{ w: "full", top: "-100px" }}
                  callback={setSearchField}
                  searchField={searchField}
                />
                <Center
                  m={{
                    base: "24px 0",
                    sm: "24px 0",
                    md: "40px 0",
                  }}
                >
                  <Accordion allowToggle={true} defaultIndex={0} w="full">
                    <AccordionItemFix>
                      <Text as="h5">
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            Category
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </Text>
                      <AccordionPanel>
                        {tagListMapKeys.map((tab, index) => (
                          <Tag
                            key={index}
                            cursor="pointer"
                            m="8px"
                            p="12px"
                            borderRadius="12px"
                            bg={index === tabIndex ? "#FBF0FF" : "#F6F7F9"}
                            onClick={() => handleTabsChange(index)}
                          >
                            {tab}
                          </Tag>
                        ))}
                      </AccordionPanel>
                    </AccordionItemFix>
                  </Accordion>
                </Center>
                {tagListMapKeys.map((key, index) => {
                  return (
                    tabIndex === index && (
                      <Flex justifyContent="center" key={index} w="full">
                        <TemplateList
                          templateList={filteredTemplates(tagListMap.get(key))}
                        />
                      </Flex>
                    )
                  );
                })}
              </Box>
            )}
          </Flex>
        </Flex>
      </Center>
    </Layout>
  );
};

export const query = graphql`
  query TemplateQuery {
    allStoryblokTag {
      edges {
        node {
          name
        }
      }
    }
    allStoryblokEntry(
      filter: {field_component: {eq: "template"}}
      sort: {fields: created_at, order: DESC}
    ) {
      nodes {
        slug
        full_slug
        content
        tag_list
        id
      }
    }
  }
`;

export default TemplatePage;
