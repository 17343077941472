import * as React from "react";
import {
  Center,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import { PublicSearch, PublicClose } from "react-notticon";

interface IProps {
  styleStuff: Object;
  callback: Function;
  searchField: string;
}

const SearchBar: React.FC<IProps> = ({ styleStuff, callback, searchField }) => {
  return (
    <Flex {...styleStuff}>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          h="56px"
          w="56px"
          lineHeight="56px"
          color="#9DA3AB"
        >
          <PublicSearch size="20px" color="#9DA3AB" />
        </InputLeftElement>
        <Input
          h="56px"
          pl="44px"
          onChange={(e) => {
            callback(e.target.value);
          }}
          value={searchField}
          lineHeight="56px"
          placeholder="Search a template"
        />
        {searchField && (
          <InputRightElement
            h="56px"
            w="56px"
            lineHeight="56px"
            color="#9DA3AB"
            cursor="pointer"
          >
            <Center
              w="36px"
              h="36px"
              _hover={{
                bg: "#F3D1FF",
              }}
              _active={{
                bg: "#E5A8FF",
              }}
              borderRadius="6px"
              onClick={() => callback("")}
              color="#9F2AEC"
            >
              <PublicClose size="20px" color="#9F2AEC" />
            </Center>
          </InputRightElement>
        )}
      </InputGroup>
    </Flex>
  );
};

export default SearchBar;
