import * as React from "react";
import { Grid, Flex, Center, Text, Box } from "@chakra-ui/react";

import StoryblokClient from "storyblok-js-client";

import { GatsbyLink } from "@/components/commons";

const { useRef, useState, useEffect, useMemo, memo } = React;

export const BlogCard: React.FC<{ data: any }> = memo(({ data }) => {
  const content = JSON.parse(data.content);
  const { template_content } = content;
  // 避免渲染过多的dom节点
  template_content.content.splice(6);

  const Storyblok = new StoryblokClient({
    accessToken: process.env.GATSBY_STORYBLOCK_ACCESS_TOKEN,
    cache: {
      clear: "auto",
      type: "memory",
    },
  });

  return (
    <Center
      bgColor="#FFFFFF"
      justifyContent="flex-start"
      height="100%"
      transform="translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)"
      transition="all 200ms cubic-bezier(.215, .61, .355, 1)"
      _hover={{
        transform:
          "translate3d(0px, 0px, 0px) scale3d(1.02, 1.02, 1.02) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 10%)",
      }}
      flexDir="column"
      borderRadius="16px"
    >
      <GatsbyLink
        display="flex"
        flexDirection="column"
        height="100%"
        to={data.slug}
      >
        <Center
          h={{
            base: "176px",
            sm: "176px",
            md: "176px",
            lg: "216px",
            xl: "216px",
            "2xl": "216px",
          }}
          w="full"
          borderTopRadius="16px"
          bgColor={data.bgColor}
          p="16px 16px 0 16px"
        >
          <Center
            borderTopRadius="8px"
            bg="#FFFFFF"
            overflow="hidden"
            h="full"
            w="full"
          >
            <Box
              className="cms-template-list"
              p="32px 24px"
              flex={1}
              h="full"
              dangerouslySetInnerHTML={{
                __html: Storyblok.richTextResolver.render(template_content),
              }}
            />
          </Center>
        </Center>
        <Flex
          maxH={{
            base: "146px",
            sm: "192px",
            md: "146px",
            lg: "154px",
            xl: "130px",
            "2xl": "130px",
          }}
          flex={1}
          p="24px"
          flexDirection="column"
          overflow="hidden"
          border="1px solid #dadee3"
          borderBottomRadius="16px"
        >
          <Text as="h5">{content?.title}</Text>
          <Text
            as="span"
            className="caption"
            mt="8px"
            noOfLines={2}
            whiteSpace="break-spaces"
          >
            {content?.description}
          </Text>
        </Flex>
      </GatsbyLink>
    </Center>
  );
});

export interface ITemplateListProps {
  templateList: [];
}

export const TemplateList: React.FC<ITemplateListProps> = (props) => {
  const { templateList } = props;

  return templateList.length === 0 ? (
    <Center>
      <Text as="span" className="caption" color="#7D848E" textAlign="center">
        Sorry, we couldn't find a match, please try another search.
      </Text>
    </Center>
  ) : (
    <Grid
      mt={{
        lg: "36px",
        xl: "36px",
        "2xl": "36px",
      }}
      gridAutoColumns="1fr"
      gridColumnGap={{
        base: "40px",
        sm: "24px",
        md: "40px",
        lg: "24px",
        xl: "40px",
        "2xl": "40px",
      }}
      gridRowGap={{
        base: "40px",
        sm: "40px",
        md: "40px",
        lg: "80px",
        xl: "80px",
        "2xl": "80px",
      }}
      gridTemplateRows="auto"
      gridTemplateColumns={{
        base: "1fr",
        sm: "1fr 1fr",
        md: "1fr 1fr",
        lg: "1fr 1fr",
        xl: "1fr 1fr",
        "2xl": "1fr 1fr 1fr",
      }}
      justifyItems="center"
      w="full"
    >
      {templateList.map((template, index) => (
        <BlogCard key={index} data={template} />
      ))}
    </Grid>
  );
};
export default memo(TemplateList);
